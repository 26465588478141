import {
  Redirect as WrongRedirect,
  Route as WrongRoute,
} from "react-router-dom";
import "./App.css";

import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Helmet } from "react-helmet";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";
import { getSessionDetails } from "./common/cookies";
import { LoginInfo, TeacherInfo } from "./types/data_types";
import { useAppDispatch, useAppSelector, useGetUserInfo } from "./app/hooks";
import { useEffect, useState, lazy, Suspense } from "react";
import axios from "axios";
import {
  API_SECURE_KEY,
  IDIGIZEN_BACKEND_URL,
  isKnownDomain,
  getMetaTags,
} from "./types/constants";

import ResponsiveSubscriber from "./components/ResponsiveSubscriber";
import { fetchQueries, testGql } from "./common/gql_queries";
import LogoutComponent from "./components/LogoutComponent";
import { getLogo, getName } from "./common/commonFunctions";
import LazyLoaderSpinner from "./components/LazyLoaderSpinner";
// import ReactGA from "react-ga";

setupIonicReact();
const Route = WrongRoute as any;
const Redirect = WrongRedirect as any;

// All Lazy loaded components
const BhpQueryResponse = lazy(() => import("./components/BhpQueryResponse"));
const ShoppingListApp = lazy(() => import("./pages/ShoppingListApp"));
const AccessDenied = lazy(() => import("./pages/AccessDenied"));
const PrivacyPolicyWrapper = lazy(
  () => import("./components/PrivacyPolicyWrapper")
);
const TermsOfserviceWrapper = lazy(
  () => import("./components/TermsOfserviceWrapper")
);
const SaveReceipt = lazy(() => import("./pages/SaveReceipt"));
const BlogPage = lazy(() => import("./pages/BlogPage"));
const SingleBlogPage = lazy(() => import("./components/SingleBlogPage"));
const BhpIntroPage = lazy(() => import("./pages/BhpIntroPage"));
const ResolveUrl = lazy(() => import("./pages/ResolveUrl"));
const PolygonMapPage = lazy(() => import("./pages/PolygonMapPage"));
const PolygonMapAdminPanel = lazy(() => import("./pages/PolygonMapAdminPanel"));
const DirectoryMarker = lazy(() => import("./pages/DirectoryMarker"));
const Home = lazy(() => import("./pages/Home"));
const RecordMarks = lazy(() => import("./pages/RecordMarks"));
const StudentReport = lazy(() => import("./pages/StudentReport"));
const RecordAttendance = lazy(() => import("./pages/RecordAttendance"));
const RecordAnecdote = lazy(() => import("./pages/RecordAnecdote"));
const PublishAnecdote = lazy(() => import("./pages/PublishAnecdote"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const FormBuilder = lazy(() => import("./pages/FormBuilder"));
const IProfile = lazy(() => import("./pages/IProfile"));
const EventPlannerPage = lazy(() => import("./pages/EventPlannerPage"));
const TableBuilder = lazy(() => import("./pages/TableBuilder"));
const Tutorials = lazy(() => import("./pages/Tutorials"));
const ThankYouPage = lazy(() => import("./pages/ThankYouPage"));
const HomeWork = lazy(() => import("./pages/HomeWork"));
const PtmLogbook = lazy(() => import("./pages/PtmLogbook"));
const GpsTrackingPage = lazy(() => import("./pages/GpsTrackingPage"));
const ImageCropper = lazy(() => import("./UI/ImageCropper"));
const CareerPredictor = lazy(() => import("./pages/CareerPredictor"));
const EcaJobIntelTraitsConsole = lazy(
  () => import("./pages/EcaJobIntelTraitsConsole")
);
const SsiEcaGradingConsole = lazy(() => import("./pages/SsiEcaGradingConsole"));
const QRCodeReader = lazy(() => import("./pages/QRCodeReader"));
const InterestSelection = lazy(() => import("./pages/InterestSelection"));
const IProfileReport = lazy(() => import("./pages/IProfileReport"));
const JobEducationConsole = lazy(() => import("./pages/JobEducationConsole"));
const GenerateReportCard = lazy(() => import("./pages/GenerateReportCard"));
const BussinessHomePage = lazy(() => import("./pages/BussinessHomePage"));
const Payment = lazy(() => import("./pages/Payment"));
const BuildResume = lazy(() => import("./pages/BuildResume"));
const EventRegisterForm = lazy(() => import("./pages/EventRegisterForm"));
const ViewImage = lazy(() => import("./pages/ViewImage"));
const QrInputPage = lazy(() => import("./pages/QrInputPage"));
const PathFinder = lazy(() => import("./components/PathFinder"));
const UpiPayment = lazy(() => import("./pages/UpiPayment"));
const NavigationPage = lazy(() => import("./components/NavigationPage"));
const LoyaltyAddRedeem = lazy(() => import("./components/LoyaltyAddRedeem"));
const TableBookingPanel = lazy(() => import("./pages/TableBookingPanel"));
const CustomerDBPage = lazy(() => import("./pages/CustomerDBPage"));
const BuildAdvertisement = lazy(() => import("./pages/BuildAdvertisement"));

// const gaTagId = "G-RHGPSENWDZ";

// ReactGA.initialize(gaTagId);

/*************************************************************************************************
 * Name: App
 * Description: Main App component. Entry point for the App.
 *************************************************************************************************/
const App: React.FC = () => {
  //const location = useLocation();
  const loginInfo: LoginInfo = useAppSelector((state) => {
    return state.loginInfoSelection;
  });
  const teacherInfo: TeacherInfo = useAppSelector((state) => {
    return state.teacherInfoSelection;
  });
  const redirectPage =
    window.location.hostname === "www.vypzee.com" ||
    window.location.hostname === "vypzee.com"
      ? "home-page"
      : window.location.hostname === "xceevf.vypzee.com"
      ? "/intro-page"
      : !isKnownDomain(window.location.hostname)
      ? `resolve-url${window.location.search}`
      : loginInfo.isLoggedIn
      ? "/landing-page"
      : "/home";
  // const dispatch = useAppDispatch();
  const [metaTags, setMetaTags] = useState<
    {
      metaTagName: string;
      metaTagDescription: string;
      type?: string;
      fullyQualified?: boolean;
    }[]
  >([]);
  // const history = useHistory();
  const { getUserInfo } = useGetUserInfo();
  //console.log("current path ", window.location);
  const { id } = getSessionDetails();
  // The check for the search params present is there because for some reason when on home landing-page called logout
  // The redirect is getting called post the logout cookies are cleared.
  if (
    !id &&
    window.location.pathname === "/landing-page" &&
    !!window.location.search
  ) {
    console.log("Redirecting ", window.location);
    let searchParam = window.location.search;
    searchParam =
      searchParam +
      `${searchParam ? "&" : "?"}` +
      `intPath=${window.location.pathname.substring(1)}`;
    console.log("Redirect to ", `${window.location.origin}/home${searchParam}`);
    const urlParams = new URLSearchParams(searchParam);
    const typeValue = urlParams.get("type");
    const idValue = urlParams.get("id");
    if (
      !(
        typeValue === "LoyaltyProgram" ||
        (typeValue === "Table" && idValue === "309") ||
        (typeValue === "Table" && idValue === "353")
      )
    )
      window.location.assign(`${window.location.origin}/home${searchParam}`);
  }

  const fetchMetaTags = async () => {
    try {
      const res = await axios.get(`${IDIGIZEN_BACKEND_URL}/${getMetaTags}`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          Authorization: API_SECURE_KEY,
          origin: window.location.origin,
          page: window.location.pathname,
        },
      });
      setMetaTags(res.data.data);
    } catch (err) {
      console.log("Error in fetching meta tags ", err);
    }
  };

  //useEffect on location change trigger to fetch metaTags for the page.
  useEffect(() => {
    fetchMetaTags();
  }, [window.location]);
  //End of useEffect on location change trigger to fetch metaTags for the page.

  useEffect(() => {
    // Dynamically change the title text
    document.title = getName(); // Replace 'New Title' with the desired new title text
    // Dynamically change the shortcut icon
    const link =
      (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `${process.env.PUBLIC_URL}/${getLogo(true)}`;
    document.getElementsByTagName("head")[0].appendChild(link);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const refresh = useAppSelector(
    (state) => state.headerNameInfoSelection
  ).refresh;
  const [logout, setLogout] = useState(false);

  // const checkGqlSanity = async () => {
  //   try {
  //     const res = await fetchQueries(testGql, { where: { id: 1 } });
  //     console.log("Response from GQL tester ", res);
  //     if (res.error.statusCode === 401) {
  //       console.log("Logout called");
  //       setLogout(true);
  //       return false;
  //     }
  //     return true;
  //   } catch (e) {
  //     console.log("Gql tester failed ", e);
  //   }
  // };

  // useEffect(() => {
  //   //console.log("Refresh called ", teacherInfo, " loginInfo ", loginInfo);
  //   const checkLoginNGetUserInfo = async () => {
  //     //hook local function to fetch teacher info
  //     if (!teacherInfo.id && loginInfo.isLoggedIn) {
  //       const status = await checkGqlSanity();
  //       if (status) {
  //         console.log("Called Refresh user info get");
  //         getUserInfo();
  //       }
  //     }
  //   };
  //   // if (isKnownDomain(window.location.hostname)) checkLoginNGetUserInfo();
  // }, []);

  useEffect(() => {
    if (loginInfo.isLoggedIn) {
      getUserInfo();
    }
  }, [refresh]);

  return (
    <>
      <ResponsiveSubscriber />
      <IonApp>
        <Helmet>
          {/*Inject fetched meta tags into document head */}
          {metaTags.map((tag, index) => {
            if (tag && tag?.metaTagName.trim().toLowerCase() === "canonical") {
              return (
                <link
                  key={index}
                  rel="canonical"
                  href={tag.metaTagDescription.trim()}
                />
              );
            } else {
              return tag?.type === "name" ? (
                <meta
                  key={index}
                  name={tag.metaTagName}
                  content={tag.metaTagDescription}
                />
              ) : tag?.type === "property" ? (
                <meta
                  key={index}
                  property={tag.metaTagName}
                  content={tag.metaTagDescription}
                />
              ) : (
                <> </>
              );
            }
          })}
        </Helmet>
        <IonReactRouter>
          <LogoutComponent
            logout={logout}
            onDidDismiss={() => {
              setLogout(false);
            }}
          />

          <IonRouterOutlet>
            <Suspense fallback={<LazyLoaderSpinner />}>
              <Route exact path="/home" key="/home">
                <Home />
              </Route>
              <Route exact path="/record-marks" key="/record-marks">
                <RecordMarks coSchol={false} />
              </Route>
              <Route
                exact
                path="/student-report-select"
                key="/student-report-select"
              >
                <StudentReport />
              </Route>
              <Route exact path="/record-attendance" key="/record-attendance">
                <RecordAttendance />
              </Route>
              <Route exact path="/record-anecdote" key="/record-anecdote">
                <RecordAnecdote />
              </Route>
              <Route exact path="/publish-anecdote" key="/publish-anecdote">
                <PublishAnecdote />
              </Route>
              <Route path="/record-behavior" key="/record-behavior">
                <RecordMarks coSchol={true} />
              </Route>
              <Route exact path="/landing-page" key="/landing-page">
                <Dashboard />
              </Route>
              <Route path="/create-form" key="/create-form">
                <FormBuilder
                  createMode={true}
                  profileMode={false}
                  type="Form"
                />
              </Route>
              <Route path="/forms" key="/forms">
                <FormBuilder
                  createMode={false}
                  profileMode={false}
                  type="Form"
                />
              </Route>
              <Route path="/table-booking-panel" key="/table-booking-panel">
                <TableBookingPanel />
              </Route>
              <Route path="/resolve-url" key="/resolve-url">
                <ResolveUrl />
              </Route>
              <Route path="/customer-db" key="/customer-db">
                <CustomerDBPage />
              </Route>
              <Route path="/access-denied" key="/access-denied">
                <AccessDenied />
              </Route>
              <Route path="/create-profile" key="/create-profile">
                <FormBuilder createMode={true} profileMode={true} type="Form" />
              </Route>
              <Route path="/create-ad" key="/create-ad">
                <BuildAdvertisement />
              </Route>
              <Route path="/intro-page" key="/intro-page">
                <BhpIntroPage />
              </Route>
              <Route exact path="/blogs" key="/blogs">
                <BlogPage />
              </Route>
              <Route exact path="/single-blog/:postId">
                <SingleBlogPage />
              </Route>
              <Route path="/loyalty-panel" key="/loyalty-panel">
                <LoyaltyAddRedeem pageMode={true} />
              </Route>
              <Route path="/save-receipt" key="/save-receipt">
                <SaveReceipt />
              </Route>
              <Route path="/path-finder" key="/path-finder">
                <PathFinder />
              </Route>
              <Route path="/directory-maker" key="/directory-maker">
                <DirectoryMarker />
              </Route>
              <Route path="/profile" key="/profile">
                <FormBuilder
                  createMode={false}
                  profileMode={true}
                  type="Form"
                />
              </Route>
              <Route exact path="/iprofile" key="/iprofile">
                <IProfile />
              </Route>
              <Route
                exact
                path="/iprofile-event-view"
                key="/iprofile-event-view"
              >
                <IProfile eventMode={true} />
              </Route>
              <Route path="/events-page" key="/events-page">
                <EventPlannerPage />
              </Route>
              <Route path="/create-table" key="/create-table">
                <TableBuilder builderMode={true} type="Table" />
              </Route>
              <Route path="/shopping-list" key="/shopping-list">
                <ShoppingListApp />
              </Route>
              <Route path="/tables" key="/tables">
                <TableBuilder builderMode={false} type="Table" />
              </Route>
              <Route path="/tutorial" key="/tutorial">
                <Tutorials />
              </Route>
              <Route path="/thank-you" key="/thank-you">
                <ThankYouPage />
              </Route>
              <Route path="/privacy-policy" key="/privacy-policy">
                <PrivacyPolicyWrapper />
              </Route>

              <Route path="/terms-of-services" key="/terms-of-service">
                <TermsOfserviceWrapper />
              </Route>

              <Route path="/navigation" key="/navigation">
                <NavigationPage />
              </Route>

              <Route path="/reply" key="/reply">
                <BhpQueryResponse />
              </Route>

              <Route path="/maps" key="/maps">
                <PolygonMapPage />
              </Route>
              <Route path="/maps-create" key="/maps-create">
                <PolygonMapAdminPanel />
              </Route>
              <Route path="/upi-payment" key="/upiPayment">
                <UpiPayment />
              </Route>
              <Route path="/home-work" key="/home-work">
                <HomeWork type="homework" />
              </Route>
              <Route path="/communication" key="/communication">
                <HomeWork type="parentcomm" />
              </Route>
              <Route path="/ptm-logbook" key="/ptm-logbook">
                <PtmLogbook />
              </Route>
              <Route path="/gps-tracker" key="/gps-tracker">
                <GpsTrackingPage />
              </Route>
              <Route path="/cropper" key="/cropper">
                <ImageCropper />
              </Route>
              <Route path="/career-predictor" key="/career-predictor">
                <CareerPredictor />
              </Route>
              <Route path="/intel-trait-console" key="/intel-trait">
                <EcaJobIntelTraitsConsole />
              </Route>
              <Route path="/ssi-eca-grading" key="/ssi-eca-grading">
                <SsiEcaGradingConsole />
              </Route>
              <Route path="/qr-code-reader" key="/qr-code-reader">
                <QRCodeReader />
              </Route>
              <Route path="/interest-select" key="/interest-select">
                <InterestSelection
                  type="ECA"
                  nextPath="/subjects-select"
                  disableBackButton={true}
                  header="Select 3 Interests..."
                />
              </Route>
              <Route path="/job-select" key="/job-select">
                <InterestSelection
                  type="Job"
                  nextPath="/"
                  header="Select Career Roles..."
                />
              </Route>
              <Route path="/subjects-select" key="/subjects-select">
                <InterestSelection
                  type="Subjects"
                  nextPath="/job-select"
                  header="Select 3 Interest Subjects..."
                />
              </Route>
              <Route path="/profile-report" key="/profile-report">
                <IProfileReport />
              </Route>
              <Route path="/job-edu-report" key="/job-edu-report">
                <JobEducationConsole />
              </Route>
              {/* <Route path="/bussiness-home-page" key="/bussiness-home-page">
              <BussinessHomePage />
            </Route> */}

              <Route
                exact
                path="/generate-report-card"
                key="/generate-report-card"
              >
                <GenerateReportCard />
              </Route>
              <Route exact path="/products/:company" key="/products">
                <BussinessHomePage />
              </Route>
              <Route exact path="/home-page" key="/home-page">
                <BussinessHomePage />
              </Route>
              <Route exact path="/directory-page" key="/directory-page">
                <BussinessHomePage directoryMode={true} />
              </Route>

              <Route exact path="/catalogue" key="/catalogue-int">
                <BussinessHomePage isInternal={true} />
              </Route>
              <Route exact path="/business/:company">
                <BussinessHomePage />
              </Route>
              <Route exact path="/directory/:company">
                <BussinessHomePage directoryMode={true} />
              </Route>
              <Route path="/:state/:city/:market/:shop?">
                <BussinessHomePage />
              </Route>
              <Route exact path="/" key="/">
                <Redirect to={redirectPage} />
              </Route>
              <Route exact path="/Payment" key="/Payment">
                <Payment />
              </Route>
              <Route exact path="/build-resume" key="/build-resume">
                <BuildResume />
              </Route>
              <Route exact path="/order-status" key="/order-status">
                <ThankYouPage />
              </Route>
              <Route exact path="/event-register" key="/event-register">
                <EventRegisterForm />
              </Route>
              <Route exact path="/view-image" key="/view-image">
                <ViewImage />
              </Route>
              <Route exact path="/qr-input-page" key="/qr-input-page">
                <QrInputPage />
              </Route>
              {/* <Route path="*">
                <AccessDenied is404={true} />
              </Route> */}
            </Suspense>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </>
  );
};

export default App;
